import React, { useState, useEffect } from "react"
import { Dialog } from "@blueprintjs/core"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import Button from "@bit/azheng.joshua-tree.button"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import Text from "../../components/Text"
import CloseIcon from "../../components/CloseIcon"
import { Section } from "../../components/Containers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight"
import { faChevronSquareUp } from "@fortawesome/pro-duotone-svg-icons/faChevronSquareUp"

const Services = ({ post }) => {
  if (!post.hasThisSection) return ""

  const { width } = useWindowDimensions()

  const [activeItem, setActiveItem] = useState(post.items[0])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      document.querySelector("html").style.overflowY = "hidden"
    } else {
      document.querySelector("html").style.removeProperty("overflow-y")
    }

    return () => {
      document.querySelector("html").style.removeProperty("overflow-y")
    }
  }, [isModalOpen])

  const handleServiceChange = service => {
    setActiveItem(service)
    const el = document.getElementsByClassName("services-menu")[0]
    window.scroll({ top: el.offsetTop - 100, left: 0, behavior: "smooth" })
  }

  const handleModal = service => {
    if (width <= 767) {
      setActiveItem(service)
      setIsModalOpen(true)
    } else {
      handleServiceChange(service)
    }
  }

  const scrollUp = () => {
    const el = document.querySelector("#modal-top")
    //window.scroll({ top: (el.offsetTop - 100), left: 0, behavior: 'smooth' });
    el.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  return (
    <Section>
      <div className="home-services">
        <div className="columns">
          <div className="column is-3"></div>
          <div className="column" data-aos-duration="1200" data-aos="fade-left">
            <h2 className="has-text-centered-tablet h3-style">
              {post.heading}
            </h2>
            <MarkdownViewer
              markdown={post.blurb}
              className="home-services-blurb"
            />

            <div className="services-menu">
              <div className="services-menu-list">
                {post.items.map((item, i) => (
                  <div
                    className="service-item"
                    key={i}
                    onClick={() => handleModal(item)}>
                    <ImageMeta
                      cloudName="nuvolum"
                      publicId={item.imageId}
                      responsive
                      width="auto"
                    />
                    <Text
                      role="heading"
                      as="p"
                      className="service-item-heading"
                      text={item.heading}
                    />
                    <div
                      className={`service-icon ${
                        item.heading === activeItem.heading ? "icon-active" : ""
                      }`}
                      onClick={() => handleServiceChange(item)}>
                      <FontAwesomeIcon
                        className="button-icon"
                        icon={faChevronRight}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="services-menu-content">
                {post.items.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item.heading === activeItem.heading
                        ? "active-content"
                        : "hidden-content"
                    }>
                    <Text
                      role="heading"
                      as="p"
                      className="service-item-heading"
                      text={item.heading}
                    />
                    <MarkdownViewer markdown={item.blurb} />
                  </div>
                ))}
              </div>
            </div>

            {/* <ButtonGroupMap isCentered buttons={post.buttons} /> */}
          </div>
          <div className="column is-3"></div>
        </div>
      </div>

      <Dialog
        id="modal-top"
        canOutsideClickClose={true}
        enforceFocus={true}
        autoFocus={true}
        canEscapeKeyClose={true}
        isOpen={isModalOpen}
        usePortal={true}
        onClose={() => setIsModalOpen(false)}
        className="anesthesia-dialog modal-top">
        <div className="dialog-content services-modal">
          <div
            id="modal-top"
            style={{ position: "absolute", top: "-100px", left: "0" }}></div>

          <CloseIcon onClick={() => setIsModalOpen(false)} />
          <p role="heading" className="service-item-heading">
            {activeItem.heading}
          </p>
          <MarkdownViewer markdown={activeItem.blurb} />

          <div className="scroll-up" onClick={scrollUp}>
            <FontAwesomeIcon className="button-icon" icon={faChevronSquareUp} />
          </div>
        </div>
      </Dialog>
    </Section>
  )
}

export default Services
