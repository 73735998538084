/* eslint-disable prefer-destructuring */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import StaticHero from "./index/StaticHero"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import LearnMoreCards from "./index/LearnMoreCards"
import Exparel from "./index/Exparel"
import OurMission from "./index/OurMission"
import Services from "./index/Services"
import ImageStrip from "./index/ImageStrip"
import NewToast from "@bit/azheng.joshua-tree.new-toast"
import FixedFacewall from "../components/carousel/FixedFacewall"
import { sortReviewsByDate } from "../utils/utils"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false,
      language: "en"
    }
    this.toggleCarousel = this.toggleCarousel.bind(this)
    // this.window = window;

    // this.overrideTheater = this.overrideTheater.bind(this);
  }
  
  toggleCarousel() {
    var that = this
    that.setState({ toggleCarouselsClass: true })
    window.removeEventListener(
      "aos:in:home-section",
      this.toggleCarousel,
      false
    )
    window.removeEventListener(
      "aos:in:featured-carousel",
      this.toggleCarousel,
      false
    )
    window.removeEventListener("scroll", this.toggleCarousel, false)
  }

  componentDidMount() {
    //document.body.style.height = "102vh";
    //document.body.style.overflow = "scroll !important";
    window.addEventListener("aos:in:home-section", this.toggleCarousel)
    window.addEventListener("aos:in:featured-carousel", this.toggleCarousel)
    window.addEventListener("scroll", this.toggleCarousel)

    if (this.props.pageContext && this.props.pageContext.language) {
      this.setState({
        language: this.props.pageContext.language
      })
    }
  }
  render() {
    const { pageContext, data, location } = this.props
    let post
    const { language } = pageContext

    if (pageContext && pageContext.language) {
      switch (language) {
        case "en":
          post = data.allUniquePagesJson.nodes[0]
          break
        case "es":
          post = data.spanishIndex.nodes[0]
          break
        default:
          post = data.allUniquePagesJson.nodes[0]
      }
    } else {
      post = data.allUniquePagesJson.nodes[0]
    }

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: pageContext.dateModified,
        language,
        metaDescription: post.metaDescription,
        path: location.pathname
      }
    }

    let allReviews = this.props.data.allReviews.nodes
    let fixedFacewallReviews = sortReviewsByDate(allReviews)
    fixedFacewallReviews = fixedFacewallReviews.slice(0, 8)

    return (
      <SharedStateProvider>
        <Layout
          className={`main-homepage ${language === "es" ? "es" : null}`}
          language={language}
          layoutClass="homepage-fab"
          noFab
          pageTitle="main-homepage">
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaData={schemaData}
            pathname={location.pathname}
          />
          <div className="static-container nav-spacer" style={staticStyle}>
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
                language={language}
              />
            </Sizzle>{" "}
            {/* <ImageStrip />
            <StaticHero post={post} /> */}
            <ImageText language={language} post={post} imageText noReverse colorBack />
            <OurMission
              language={language}
              hasThisSection={post.mission.hasThisSection}
              heading={post.mission.heading}
              blurb={post.mission.blurb}
            />
            <Services language={language} post={post.services} />
            <FixedFacewall
              colorBack
              language={language}
              heading={
                language === "es"
                  ? "Escuche Sobre Nuestros Pacientes"
                  : "Hear From Our Patients"
              }
              reviews={fixedFacewallReviews}
              button
            />
            <FirstTime colorBack language={language} post={post} noReverse />
          </div>
          {/* <NewToast>
            <p>Sign up for our <Link to="/defend" title="Sign up for Defend Your Mouth Day">Defend Your Mouth Day</Link> on Friday, July 29 for a FREE professionally fitted mouth guard!</p>
          </NewToast> */}
        </Layout>
      </SharedStateProvider>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { ne: "ESP" } }
    ) {
      nodes {
        ...FacewallData
      }
    }
    allReviewsEs: allSpanishReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ESP" } }
      limit: 4
    ) {
      nodes {
        title
        reviewerName
        reviewType
        reviewLanguage
        thumbnailPublicId
        mainProcedureDone
        monthYear {
          month
          year
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        schemas {
          presets
          custom
        }
        blurb
        hasSizzle
        mobileHeading
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          heading
          imageId
          text
        }
        mission {
          hasThisSection
          heading
          blurb
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          button {
            buttonText
            href
            destination
            appearance
          }
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        svgHeadingMobile
      }
    }
    spanishIndex: allSpanishUniquePagesJson(filter: { title: { eq: "/es/" } }) {
      nodes {
        hasSizzle
        mobileHeading
        staticHero {
          heading
          subHeading
          sideImage
          logo
          backgroundImage
        }
        homeSectionOne {
          heading
          imageId
          text
        }
        imageTexts {
          hasThisSection
          textLeft
          leftColWidth
          leftContentColWidth
          middleColWidth
          rightColWidth
          text
          imageId
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeExparel {
          hasThisSection
          imageId
          blurb
          button {
            buttonText
            href
          }
        }
        homeAsird {
          blurb
          hasThisSection
          imageId
          button {
            buttonText
            href
          }
        }
        homeAAAHC {
          blurb
          heading
          hasThisSection
          backgroundImage
          logos {
            imageId
          }
          button {
            useButton
            buttonText
            href
          }
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          buttonOne {
            buttonText
            href
          }
        }
        homeHeadingVideo {
          hasThisSection
          heading
          youtube
          youtubePhotoPublicId
          youtubePhotoPublicIdMobile
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        referringProvider {
          youtube
          imageCaption
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
        svgHeadingMobile
      }
    }
  }
`

export default IndexPage
